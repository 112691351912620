<template>
  <div>
    <!--    通知框-->
    <v-snackbar
        timeout="2000"
        :value="snackbarShow"
        :color="snackbarColor"
        absolute
        top
        v-model="snackbarShow"
    >{{ snackbarText }}
    </v-snackbar>
    <!--    筛选-->
    <v-card>
      <v-toolbar
          flat
      >
        <v-breadcrumbs :items="guildBar">
          <template v-slot:divider>
            <v-icon>mdi-chevron-right</v-icon>
          </template>
        </v-breadcrumbs>
      </v-toolbar>
      <v-divider></v-divider>
      <div class="horizontal">
        <span class="tagName">小区名称：</span>
        <v-chip-group
            active-class="purple  white--text"
            column>
          <v-chip v-for="(item,index) in selectMenu" @click="findBuilding(item,index)" class="ml-1"
                  style="font-size: 10px">
            {{ item.estateName }}
          </v-chip>
        </v-chip-group>
      </div>
      <!--筛选小区楼栋-->
      <div v-if="chipShow1">
        <v-divider></v-divider>
        <div class="horizontal">
          <span class="tagName">小区楼栋：</span>
          <v-chip-group
              active-class="purple  white--text"
              column>
            <v-chip v-for="(item,index) in selectMenu[clickIndex1].buildings" @click="findRoom(item,index)" class="ml-1"
                    style="font-size: 10px">
              {{ item.buildingName }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>
      <!--筛选房间号-->
      <div v-if="chipShow2">
        <v-divider></v-divider>
        <div class="horizontal">
          <span class="tagName">房间号：</span>
          <v-chip-group
              active-class="purple  white--text"
              column>
            <v-chip v-for="item in selectMenu[clickIndex1].buildings[clickIndex2].roomInfos"
                    @click="roomShow(item)" class="ml-1" style="font-size: 10px">
              {{ item.roomName }}
            </v-chip>
          </v-chip-group>
        </div>
      </div>
    </v-card>
    <!--    表格-->
    <div class="table">
      <div>
        <v-card>
          <v-data-table
              :loading="loading"
              loading-text="加载中,请稍等..."
              :headers="headers"
              :items="desserts"
              :search="search"
              sort-by="calories"
              class="elevation-1"
              :footer-props="{
       showFirstLastPage: true,
       itemsPerPageText:'每页行数：',
    }"
          >
            <template v-slot:top>
              <v-toolbar
                  flat
              >
                <v-toolbar-title>{{ tableTitle }}</v-toolbar-title>
                <!--          divider表示分割线-->
                <v-divider
                    class="mx-5"
                    inset
                    vertical
                ></v-divider>
                <v-btn
                    v-show="addBtnShow"
                    color="primary"
                    dark
                    class="mb-2"
                    @click="dialogShow"
                >
                  {{ addBtnText }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="搜索"
                    single-line
                    hide-details
                ></v-text-field>
              </v-toolbar>
            </template>
            <!--      数据操作列-->
            <template v-slot:item.actions="{ item,index }">
              <v-btn
                  small
                  class="primary mr-1"
                  @click="dialogShow(item,index)"
              >
                编辑
              </v-btn>
              <v-btn
                  small
                  class="error"
                  @click="deleteItem(item)"
              >
                删除
              </v-btn>
            </template>
            <template v-slot:item.safeAction="{ item }">
              <v-btn
                  small
                  class="secondary mr-1"
                  @click="showSafeInfo(item)"
              >
                查看
              </v-btn>
            </template>
            <template v-slot:no-data>
              <v-btn
                  color="primary"
                  @click="initialize"
              >
                Reset
              </v-btn>
            </template>
          </v-data-table>
        </v-card>

      </div>
    </div>

    <!--          小区编辑弹框-->
    <v-dialog
        v-model="estateDialog"
        scrollable
        max-width="500px"
    >
      <!--            弹框具体内容-->
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">

                <div class="text_title mb-2 mr-5"><p>{{ textList1.provinceName }}</p></div>
                <div class="text_filed">
                  <v-select
                      v-model="editedItem1.provinceName"
                      :items="provinceItem"
                      label="所属辖区"
                      solo
                  ></v-select>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">
                <div class="text_title mb-2 mr-5"><p>{{ textList1.estateName }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem1.estateName"
                      solo
                      :disabled="dialogItemShow"
                      dense
                      label="小区名称"
                      type="select"
                      :rules="rules.notnull"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">
                <div class="text_title mb-2 mr-5"><p>{{ textList1.builtTime }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem1.builtTime"
                      solo
                      type="date"
                      dense
                      :rules="rules.notnull"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">

                <div class="text_title mb-2 mr-5"><p>{{ textList1.serviceLife }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem1.serviceLife"
                      solo
                      type="number"
                      label="0"
                      dense
                      :rules="rules.notnull"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">

                <div class="text_title mb-2 mr-5"><p>{{ textList1.personalDensity }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem1.personalDensity"
                      solo
                      type="number"
                      label="0"
                      dense
                      :rules="rules.notnull"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">

                <div class="text_title mb-2 mr-5"><p>{{ textList1.estateArea }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem1.estateArea"
                      solo
                      dense
                      type="number"
                      label="0"
                      :rules="rules.notnull"
                  ></v-text-field>
                </div>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            取消
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    楼栋编辑弹框-->
    <v-dialog
        v-model="buildingDialog"
        scrollable
        max-width="500px"
    >

      <!--            弹框具体内容-->
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">

                <div class="text_title mb-2 mr-5"><p>{{ textList2.buildingName }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem2.buildingName"
                      solo
                      dense
                      :disabled="dialogItemShow"
                      :rules="rules.notnull"
                      label="楼栋号"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">
                <div class="text_title mb-2 mr-5"><p>{{ textList2.residentsNumber }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem2.residentsNumber"
                      solo
                      type="number"
                      label="居住人数"
                      dense
                      :rules="rules.notnull"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">
                <div class="text_title mb-2 mr-5"><p>{{ textList2.floor }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem2.floor"
                      solo
                      type="number"
                      dense
                      :rules="rules.notnull"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">

                <div class="text_title mb-2 mr-5"><p>{{ textList2.isElevator }}</p></div>
                <div class="text_filed">
                  <v-select
                      v-model="editedItem2.isElevator"
                      solo
                      :items="elevatorItem"
                      dense
                      label="有无电梯"
                      :rules="rules.notnull"
                  ></v-select>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">

                <div class="text_title mb-2 mr-5"><p>{{ textList2.buildingTypeName }}</p></div>
                <div class="text_filed">
                  <v-select
                      v-model="editedItem2.buildingTypeName"
                      solo
                      :items="buildingTypeItem"
                      label="0"
                      dense
                      :rules="rules.notnull">
                  </v-select>
                </div>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            取消
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--    房间编辑弹框-->
    <v-dialog
        v-model="roomDialog"
        scrollable
        max-width="500px"
    >
      <!--            弹框具体内容-->
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">

                <div class="text_title mb-2 mr-5"><p>{{ textList3.roomName }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem3.roomName"
                      solo
                      dense
                      :rules="rules.notnull"
                      label="房屋编号"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">
                <div class="text_title mb-2 mr-5"><p>{{ textList3.roomType }}</p></div>
                <div class="text_filed">
                  <v-select
                      v-model="editedItem3.roomType"
                      solo
                      :items="roomUseTypeItem"
                      dense
                      label="房屋用途类型"
                      :rules="rules.notnull"
                  ></v-select>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">
                <div class="text_title mb-2 mr-5"><p>{{ textList3.roomArea }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem3.roomArea"
                      solo
                      type="number"
                      dense
                      :rules="rules.notnull"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">

                <div class="text_title mb-2 mr-5"><p>{{ textList3.roomHeight }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem3.roomHeight"
                      solo
                      type="number"
                      label="0"
                      dense
                      :rules="rules.notnull"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">

                <div class="text_title mb-2 mr-5"><p>{{ textList3.roomPersonalType }}</p></div>
                <div class="text_filed">
                  <v-select
                      v-model="editedItem3.roomPersonalType"
                      solo
                      label="房屋居住人员特征"
                      :items="peopleTypeItem"
                      dense
                      :rules="rules.notnull"
                  ></v-select>
                </div>
              </v-col>
              <v-col
                  cols="12"
                  class="d-flex flex-row justify-space-between align-center mb-n4">

                <div class="text_title mb-2 mr-5"><p>{{ textList3.personalContact }}</p></div>
                <div class="text_filed">
                  <v-text-field
                      v-model="editedItem3.personalContact"
                      solo
                      dense
                      label="电话"
                      :rules="rules.phone"
                  ></v-text-field>
                </div>
              </v-col>

            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            取消
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            保存
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--          删除弹框-->
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-h5">确定删除该条信息吗?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">取消</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">确定</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>

</template>

<script>
import HouseBasicInfo from "@/views/house_management/HouseBasicInfo";
import {
  addBuildingInfo,
  addEstateInfo, addRoomInfo, deleteBuildingInfo, deleteEstateInfo, deleteRoomInfo, editBuildingInfo,
  editEstateInfo, editRoomInfo, getAllBuildingType, getAllProvince,
  getBuildingInfo,
  getEstateInfo, getPersonType,
  getRoomInfo, getRoomUseType,
  getSelectInfo
} from "@/network/Details/houseManagement";

export default {
  name: 'HouseSafeInfo',
  data: () => ({
    loading:true,
    deleteRoomName:'',
    buildingId:'',
    buildingName:'',
    peopleTypeItem: '',
    roomUseTypeItem: '',
    addBtnShow: true,
    deleteBuildingID: '',
    elevatorItem: ['是', '否'],
    buildingTypeItem: [],
    //存放小区名称
    estateText: '',
    //存放当前的保存按钮
    currentSaveBtn: 1,
    //删除项的id
    deleteEstateID: '',
    //提示
    snackbarShow: false,
    snackbarText: '',
    snackbarColor: '#42A5F5',
    //获取省的数据项
    provinceItem: '',
    estateId: '',
    chipShow1: false,
    chipShow2: false,
    dialogItemShow: true,
    tableTitle: '小区信息',
    clickIndex1: 0,
    clickIndex2: 0,
    guildBar: [
      {
        text: '房屋管理',
        disabled: false,
      }
    ],
    //上部分的筛选内容数组
    selectMenu: [],
    currentDialog: 1,
    //表单验证
    roomDialog: false,
    buildingDialog: false,
    estateDialog: false,
    search: '',
    flag: true,
    dialogDelete: false,
    headers: [],
    headers1: [{text: '所属辖区', align: 'start', sortable: false, value: 'provinceName',},
      {text: '小区名称', value: 'estateName', sortable: false},
      {text: '建筑时间', value: 'builtTime'},
      {text: '使用年限', value: 'serviceLife'},
      {text: '人员密度（人/m²）', value: 'personalDensity'},
      {text: '占地总面积/m²', value: 'estateArea'},
      {text: '操作', value: 'actions', sortable: false},],
    headers2: [{text: '楼栋号', align: 'start', sortable: false, value: 'buildingName',},

      {text: '居住人数', value: 'residentsNumber', sortable: false},
      {text: '楼层数', value: 'floor'},
      {text: '有无电梯', value: 'isElevator'},
      {text: '楼栋类型', value: 'buildingTypeName'},
      {text: '安全信息', value: 'safeAction', sortable: false},
      {text: '录入情况', sortable: false, value: 'statue',},
      {text: '操作', value: 'actions', sortable: false},],
    headers3: [{text: '房屋编号', align: 'start', sortable: false, value: 'roomName',},
      {text: '房屋用途类型', sortable: false, value: 'roomTypeName',},
      {text: '房屋建筑面积', value: 'roomArea', sortable: false},
      {text: '房屋高度', value: 'roomHeight'},
      {text: '居住人员类型', value: 'roomPersonalTypeName'},
      {text: '居住人联系方式', value: 'personalContact'},
      {text: '操作', value: 'actions', sortable: false},],
    useWay: ['闲置', '租赁', '商用', '其他', '住宿'],
    desserts: [],
    editedIndex: -1,
    // 编辑项
    editedItem1: {
      provinceName: '',
      estateName: '',
      builtTime: '',
      serviceLife: '',
      personalDensity: '',
      estateArea: '',
    },
    temp1: {
      provinceName: '',
      estateName: '',
      builtTime: '',
      serviceLife: '',
      personalDensity: '',
      estateArea: '',
    },
    editedItem2: {
      buildingName: '',
      residentsNumber: '',
      floor: '',
      isElevator: '',
      buildingTypeName: '',
      safeAction: '',
    },
    editedItem3: {
      roomName: '',
      roomType: '',
      roomArea: '',
      roomHeight: '',
      roomPersonalType: '',
      personalContact: '',
    },
    textList1: {
      provinceName: '所属辖区',
      estateName: '小区名称',
      builtTime: '建筑时间',
      serviceLife: '使用年限',
      personalDensity: '人员密度',
      estateArea: '占地面积',
    },
    textList2: {
      buildingName: '楼栋号',
      residentsNumber: '居住人数',
      floor: '楼层数',
      isElevator: '有无电梯',
      buildingTypeName: '楼栋类型',
      safeAction: '安全信息',
      inputStatus: '录入情况'
    },
    textList3: {
      roomName: '房屋编号',
      roomType: '房屋用途类型',
      roomArea: '房屋建筑面积',
      roomHeight: '房屋高度',
      roomPersonalType: '居住人员特征',
      personalContact: '居住人联系方式',

    },
    rules: {
      notnull: [
        v => !!v || "不能为空",
      ],
      phone: [
        v => !!v || "不能为空",
        v => /^1[0-9]{10}$/.test(v) || "请输入合法电话"
      ]
    },
    addBtnText: '新增信息'
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? '新建信息' : '编辑信息'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    guildBar: {
      handler(val) {
        if (val.length === 1) {
          this.tableTitle = '小区信息'
          this.currentDialog = 1;
          this.currentSaveBtn = 1;
          this.headers = this.headers1;
          this.addBtnShow = true
          this.updateEstateTable()

        } else if (val.length === 2) {
          this.tableTitle = '楼栋信息'
          this.currentDialog = 2;
          this.currentSaveBtn = 2;
          this.headers = this.headers2;
          this.estateText = val[1].text
          this.addBtnShow = true
          this.updateBuildingTable(val[1].id)

        } else if (val.length === 3) {
          this.tableTitle = '房屋信息',
              this.currentDialog = 3;
          this.currentSaveBtn = 3;
          this.headers = this.headers3;
          this.addBtnShow = true
          this.updateRoomTable(val[1].id, val[2].id)

        } else {
          this.tableTitle = '房屋信息';
          this.currentDialog = 3;
          this.currentSaveBtn = 3;
          this.headers = this.headers3;
          this.updateRoomTable(this.estateId,this.buildingId,this.roomName)

          this.addBtnShow = false
        }
      },
      deep: true
    }
  },

  methods: {
    updateSelectMenu() {
      getSelectInfo().then(
          res => {
            this.selectMenu = res.data;
            this.loading = false;
          }
      ).catch(err => {
        console.log(err)
      })
    },
    //更新小区表格
    updateEstateTable() {
      getEstateInfo({}).then(
          res => {
            this.desserts = res.data;
            this.loading = false;
          }
      ).catch(err => {
        console.log(err)
      })
    },
    //更新楼栋表格
    updateBuildingTable(estateId) {
      getBuildingInfo({estateID: estateId}).then(
          res => {
            res.data.forEach(v => {
              v.isElevator =  v.isElevator === true ? '是' : '否';
              v.statue = v.statue === 0 ? '未录入' : '录入';
            });
            this.desserts = res.data;
            this.loading = false;
          }
      ).catch(
          err => {
            console.log(err)
          }
      )
    },
    //更新房屋表格
    updateRoomTable(estateId, buildingId,roomName) {
      getRoomInfo({
        estateID: estateId,
        buildingID: buildingId,
        roomName:roomName,
      }).then(res => {
        this.desserts = res.data;
        this.loading = false;
      }).catch(err => {
        console.log(err)
      })
    },
    //添加信息按钮的弹出弹窗
    dialogShow(item, index) {
      this.editedIndex = index
      //用dialogItemShow来判断是编辑弹框还是添加弹框，如果为true则为编辑框
      this.dialogItemShow = item.isTrusted === undefined;
      //弹出的是小区信息的弹框
      if (this.currentDialog === 1) {
        this.editedItem1 = item;
        this.estateDialog = true;
        return;
      }
      //弹出的是楼栋信息的弹框
      if (this.currentDialog === 2) {
        this.editedItem2 = item;
        this.buildingDialog = true
        return;
      }
      //弹出房间信息的弹框
      this.roomDialog = true
      this.editedItem3 = item;

    },
    //展示安全信息
    showSafeInfo(item) {
      this.$router.push('/safeInfo?buildingId='+item.buildingID)
    },
    //导航栏展示楼栋
    findBuilding(item, index) {
      this.estateId = item.estateID
      let temp = {
        text: item.estateName,
        disabled: false,
        id: item.estateID
      }
      if (this.guildBar.length > 1) {
        if (item.estateName === this.guildBar[1].text) {
          this.guildBar.splice(1, 3);
          this.chipShow1 = false;
          this.chipShow2 = false;
        } else {
          this.guildBar.splice(1, 1, temp);
        }
      } else {
        this.guildBar.splice(1, 1, temp);
        this.chipShow1 = true;
      }
      this.clickIndex1 = index;
      this.currentDialog = 2;
    },
    //导航栏展示房间
    findRoom(item, index) {
      let temp = {
        text: item.buildingName,
        disabled: false,
        id: item.buildingID
      }
      this.buildingName=item.buildingName
      this.buildingId=item.buildingID
      if (this.guildBar.length > 2) {
        if (item.buildingName === this.guildBar[2].text) {
          this.guildBar.splice(2, 2);
          this.chipShow2 = false;
        } else {
          this.guildBar.splice(2, 1, temp);
        }
      } else {
        this.guildBar.splice(2, 1, temp);
        this.chipShow2 = true;
      }
      this.clickIndex2 = index;
      //设置弹窗
      this.currentDialog = 3;

    },
    //导航栏展示单个房间信息
    roomShow(item) {
      let temp = {
        text: item.roomName,
        disabled: false,
      }
      this.roomName=item.roomName
      if (this.guildBar.length > 3) {
        if (item.roomName === this.guildBar[3].text) {
          this.guildBar.splice(3, 1)
        } else {
          this.guildBar.splice(3, 1, temp)
        }
      } else {
        this.guildBar.splice(3, 1, temp)
      }

    },
    initialize() {
      this.desserts = []
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (this.estateClick) {
        this.buildingDialog = true

      } else if (this.buildingClick) {
        this.roomDialog = true
      } else {
        this.estateDialog = true
      }

    },

    deleteItem(item) {
      this.deleteEstateID = item.estateID
      this.deleteBuildingID = item.buildingID
      this.deleteRoomName=item.roomName
      console.log(this.roomName)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      if (this.currentSaveBtn === 1) {
        deleteEstateInfo({
          estateID: this.deleteEstateID
        }).then(res => {
          if (res.code === 200) {
            this.updateEstateTable()
            this.updateSelectMenu()
          } else {
            this.snackbarColor = '#DD001B'
          }
          this.snackbarText = res.message
          this.snackbarShow = true
        }).catch(err => {
          console.log(err)
        })
        this.updateEstateTable()
      }
      if (this.currentSaveBtn === 2) {
        deleteBuildingInfo({
          estateID: this.estateId,
          buildingID: this.deleteBuildingID
        }).then(res => {
          if (res.code === 200) {
            this.updateSelectMenu()
            this.updateBuildingTable(this.estateId)
          } else {
            this.snackbarColor = '#DD001B'
          }
          this.snackbarText = res.message
          this.snackbarShow = true
        }).catch(err => {
          console.log(err)
        })
      }
      if(this.currentSaveBtn===3){
        deleteRoomInfo({
          roomName:this.deleteRoomName,
          buildingID:this.buildingId,
          estateID:this.estateId,
        }).then(res=>{
          console.log(res)
          if (res.code === 200) {
            this.updateRoomTable(this.estateId,this.buildingId)
            this.updateSelectMenu()
          } else {
            this.snackbarColor = '#DD001B'
          }
          this.snackbarText = res.message
          this.snackbarShow = true
        }).catch(err=>{
          console.log(err)
        })
      }
      this.closeDelete()
    },

    close() {
      this.estateDialog = false
      this.roomDialog = false
      this.buildingDialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    //保存按钮
    save() {
      //更改房屋信息
      if (this.dialogItemShow) {

        //如果等于1表示是小区的编辑按钮
        if (this.currentSaveBtn === 1) {
          for (let x in this.editedItem1) {
            if (this.editedItem1[x] === '') {
              alert("数据不能为空！")
              return;
            }
          }
          editEstateInfo({
            estateName: this.editedItem1.estateName,
            provinceName: this.editedItem1.provinceName,
            builtTime: this.editedItem1.builtTime,
            serviceLife: this.editedItem1.serviceLife,
            estateArea: this.editedItem1.estateArea,
            personalDensity: this.editedItem1.personalDensity
          }).then(res => {
            if (res.code === 200) {
              this.updateEstateTable()
            } else {
              this.snackbarColor = '#DD001B'
            }
            this.snackbarText = res.message
            this.snackbarShow = true
          }).catch(err => {
            console.log(err)
          })
        }
        //修改楼栋信息
        if (this.currentSaveBtn === 2) {
          for (let x in this.editedItem2) {
            if (this.editedItem2[x] === '') {
              alert("数据不能为空！")
              return;
            }
          }
          let isElevator;
          if (this.editedItem2.isElevator === '是') {
            isElevator = true
          } else isElevator = false
          editBuildingInfo({
            estateName: this.estateText,
            buildingName: this.editedItem2.buildingName,
            residentsNumber: this.editedItem2.residentsNumber,
            floor: this.editedItem2.floor,
            isElevator: isElevator,
            buildingTypeName: this.editedItem2.buildingTypeName
          }).then(res => {
            if (res.code === 200) {
              this.updateBuildingTable(this.estateId)
            } else {
              this.snackbarColor = '#DD001B'
            }
            this.snackbarText = res.message
            this.snackbarShow = true
          }).catch(err => {
            console.log(err)
          })
        }
        if (this.currentSaveBtn === 3) {
          for (let x in this.editedItem3) {
            if (this.editedItem3[x] === '') {
              alert("数据不能为空！")
              return;
            }
          }

          editRoomInfo({
            roomName: this.editedItem3.roomName,
              estateName:this.estateText,
            buildingName:this.buildingName,
            roomArea: this.editedItem3.roomArea,
            roomTypeName: this.editedItem3.roomType,
            roomPersonalTypeName: this.editedItem3.roomPersonalType,
            personalContact: this.editedItem3.personalContact
          }).then(res=>{
            if (res.code === 200) {
              this.updateRoomTable(this.estateId,this.buildingId)
            } else {
              this.snackbarColor = '#DD001B'
            }
            this.snackbarText = res.message
            this.snackbarShow = true
          }).catch(err => {
            console.log(err)
          })
        }
      } else {
        //新建房屋信息
        //新建小区楼栋信息
        if (this.currentSaveBtn === 1) {
          for (let x in this.editedItem1) {
            if (this.editedItem1[x] === '') {
              alert("数据不能为空！")
              return;
            }
          }
          addEstateInfo({
            estateName: this.editedItem1.estateName,
            provinceName: this.editedItem1.provinceName,
            builtTime: this.editedItem1.builtTime,
            serviceLife: this.editedItem1.serviceLife,
            estateArea: this.editedItem1.estateArea,
            personalDensity: this.editedItem1.personalDensity
          }).then(res => {
            if (res.code === 200) {
              this.updateEstateTable()
              this.selectMenu.splice(-1, 0, this.editedItem1.estateName)
            } else {
              this.snackbarColor = '#DD001B'
            }
            this.snackbarText = res.message
            this.snackbarShow = true
          }).catch(err => {
            console.log(err)
          })
        }

        //新建楼栋信息
        if (this.currentSaveBtn === 2) {
          for (let x in this.editedItem2) {
            if (this.editedItem2[x] === '') {
              alert("数据不能为空！")
              return;
            }
          }
          let isElevator;
          if (this.editedItem2.isElevator === '是') {
            isElevator = true
          } else isElevator = false
          addBuildingInfo({
            estateName: this.estateText,
            buildingName: this.editedItem2.buildingName,
            residentsNumber: this.editedItem2.residentsNumber,
            floor: this.editedItem2.floor,
            isElevator: isElevator,
            buildingTypeName: this.editedItem2.buildingTypeName
          }).then(res => {
            if (res.code === 200) {
              this.updateBuildingTable(this.estateId)
              let temp = {
                buildingName: this.editedItem2.buildingName
              }
              this.selectMenu[this.clickIndex1].buildings.push(temp);
            } else {
              this.snackbarColor = '#DD001B'
            }
            this.snackbarText = res.message
            this.snackbarShow = true
          }).catch(err => {
            console.log(err)
          })
        }
        if(this.currentSaveBtn===3){
          for (let x in this.editedItem3) {
            if (this.editedItem3[x] === '') {
              alert("数据不能为空！")
              return;
            }
          }

          addRoomInfo({
            roomName: this.editedItem3.roomName,
            estateName:this.estateText,
            buildingName:this.buildingName,
            roomArea: this.editedItem3.roomArea,
            roomTypeName: this.editedItem3.roomType,
            roomPersonalTypeName: this.editedItem3.roomPersonalType,
            personalContact: this.editedItem3.personalContact,
            roomHeight:this.editedItem3.roomHeight
          }).then(res=>{
            if (res.code === 200) {
              this.updateRoomTable(this.estateId,this.buildingId)
              this.updateSelectMenu()
            } else {
              this.snackbarColor = '#DD001B'
            }
            this.snackbarText = res.message
            this.snackbarShow = true
          }).catch(err => {
            console.log(err)
          })
        }
      }
      this.close()
    },
  },
  created() {
    this.initialize();
    this.headers = this.headers1;
    this.updateSelectMenu()
    this.updateEstateTable()
    //获取省的列表
    getAllProvince().then(
        res => {
          this.provinceItem = res.data
        }
    ).catch(
        err => {
          console.log(err)
        })
    //获取楼栋类型的列表
    getAllBuildingType({}).then(res => {
      this.buildingTypeItem = res.data
    }).catch(err => {
      console.log(err)
    })
    //获取居住人员特征
    getPersonType({}).then(res => {
      this.peopleTypeItem = res.data
    })
    getRoomUseType({}).then(res => {
      this.roomUseTypeItem = res.data
    })
  },
  components: {
    HouseBasicInfo
  }
}
</script>

<style lang="less" scoped>
.bar_top {
  height: 50px;
  display: flex;
  padding-top: 5px;
}

.horizontal {
  display: flex;
}

.tagName {
  margin-top: 13px;
  font-size: 13px;
  margin-left: 10px
}

.table {
  margin-top: 10px;
}

.text_title {
  font-size: 17px;
}

.text_filed {
  width: 300px;
}
</style>

import {request} from "@/network/request";

export function getSelectInfo(){
  return request({
      url:'/estate/selectEstateInfo',
      method:'get',
      params:''
  })
}

export function getEstateInfo(){
    return request({
        url:'/estate/selectEstateBasicInfoByEstateID',
        method:'get',
        params:''
    })
}

export function getBuildingInfo(data){
    return request({
        url:'/building/selectAllBuildingInfo',
        method:'get',
        params:data
    })
}

export function getRoomInfo(data){
    return request({
        url:'/room/selectRoomBasicInfoByRoom',
        method:'get',
        params:data
    })
}

export function addEstateInfo(data){
    return request({
        url:'/estate/addEstate',
        method:'post',
        params:data
    })
}

export  function  editEstateInfo(data){
    return request({
        url:'/estate/updateEstate',
        method:'post',
        params:data
    })
}

export  function deleteEstateInfo(data){
    return request({
        url:'/estate/deleteEstateByEstateID',
        method:'post',
        params:data

    })
}

export function  getAllProvince(){
    return request({
        url:'province/selectAllProvince',
        method:'get',
        params:''
    })
}


export function editBuildingInfo(data){
    console.log(data)
    return request({
        url:'/building/updateBuilding',
        method:'post',
        params:data
    })
}

export function getAllBuildingType(){
    return request({
        url:'/building/selectAllTypes',
        method:'get',
        params:''
    })
}


export function addBuildingInfo(data){
    return request({
        url:'/building/addBuilding',
        method:'post',
        params:data
    })
}

export function deleteBuildingInfo(data){
    return request({
        url:'/building/deleteBuilding',
        method:'post',
        params:data
    })
}


export function getRoomUseType(){
  return request({
      url:'/roomType/selectAllRoomTypeName',
      method:'get',
      params:''
  })
}

export function getPersonType(){
    return request({
        url:'/roomPersonalType/selectAllPersonalTypeName',
        method:'get',
        params:''
    })
}


export function addRoomInfo(data){
    console.log(data)
    return request({
        url:'/room/addRoom',
        method:'post',
        params:data
    })
}

export function editRoomInfo(data){
    return request({
        url:'/room//updateRoom',
        method:'post',
        params:data
    })
}


export function deleteRoomInfo(data){

    return request({
        url:'/room/deleteRoomByRoomID',
        method:'post',
        params:data
    })
}


export function  findRoomInfo(data){
    return request({
        url:'/room/selectRoomBasicInfoByRoom',
        method:'get',
        params:data
    })
}
